import AgeingTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/ageing/AgeingTemplate';
import { AMOUNT, BOOLEAN, FACE_WRINKLES } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinAppearance/Ageing/AgeingTemplate',
  component: AgeingTemplate
};

const createStory = props => () => ({
  components: { AgeingTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><ageing-template v-bind="$options.storyProps" /></div>'
});

export const required = createStory({
  wrinkles: FACE_WRINKLES.FACE_DEEP_LINES
});

export const dryingSkinVisible = createStory({
  wrinkles: FACE_WRINKLES.NO,
  dryingSkin: AMOUNT.MODERATELY,
  dryingSkinVisible: true
});

export const dryOpenPoresVisible = createStory({
  wrinkles: FACE_WRINKLES.NO,
  dryOpenPores: BOOLEAN.YES,
  dryOpenPoresVisible: true
});

export const aiSelected = createStory({
  wrinkles: FACE_WRINKLES.NO,
  wrinklesAiSelected: FACE_WRINKLES.FEW_FACE_LINES,
  dryingSkin: AMOUNT.MODERATELY,
  dryingSkinVisible: true,
  dryOpenPores: BOOLEAN.YES,
  dryOpenPoresVisible: true
});

export const all = createStory({
  wrinkles: FACE_WRINKLES.NO,
  dryingSkin: AMOUNT.MODERATELY,
  dryingSkinVisible: true,
  dryOpenPores: BOOLEAN.YES,
  dryOpenPoresVisible: true
});
